<template>
  <div>
    <b-modal
      id="modal-bet-history"
      hide-footer
      centered
      :title="$t('general.betHistory')"
      size="xl"
      @hide="isShow = false"
    >
      <b-row v-if="summary">
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.lowBet, summary.ccy)"
            :statistic-title="$t('field.lowBet')"
            color="primary"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.topBet, summary.ccy)"
            :statistic-title="$t('field.topBet')"
            color="primary"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="summary.meronCountBet"
            :statistic-title="$t('field.meronTotalBet')"
            color="meron"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.meronAvgBet, summary.ccy)"
            :statistic-title="$t('field.meronAvgBet')"
            color="meron"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.meronBet, summary.ccy)"
            :statistic-title="$t('field.meronBet')"
            color="meron"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.meronWin, summary.ccy)"
            :statistic-title="$t('field.meronWin')"
            color="meron"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="summary.drawCountBet"
            :statistic-title="$t('field.drawTotalBet')"
            color="success"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.drawAvgBet, summary.ccy)"
            :statistic-title="$t('field.drawAvgBet')"
            color="success"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.drawBet, summary.ccy)"
            :statistic-title="$t('field.drawBet')"
            color="success"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.drawWin, summary.ccy)"
            :statistic-title="$t('field.drawWin')"
            color="success"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="summary.walaCountBet"
            :statistic-title="$t('field.walaTotalBet')"
            color="wala"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.walaAvgBet, summary.ccy)"
            :statistic-title="$t('field.walaAvgBet')"
            color="wala"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.walaBet, summary.ccy)"
            :statistic-title="$t('field.walaBet')"
            color="wala"
          />
        </b-col>
        <b-col
          lg="3"
          md="12"
        >
          <StatisticCardHorizontal
            :statistic="currencyFormat(summary.walaWin, summary.ccy)"
            :statistic-title="$t('field.walaWin')"
            color="wala"
          />
        </b-col>
      </b-row>
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-0-5">
          <b-row>
            <b-col>
              <label>{{ $t("general.entries") }}</label>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block ml-50 mr-1"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <n-table
            :fields="fields"
            :items="items"
            :busy="loading"
            :current-page="params.page"
            :per-page="$store.state.pagination.perPage"
            :total="total"
            @sort-changed="sortChanged"
          >
            <template #cell(channelId)="data">
              {{ data.item.channelName }}
            </template>
            <template #cell(createdAt)="data">
              {{ data.item.createdAt | formatDate }}
            </template>
            <template #cell(no)="data">
              <span v-if="data.item.betType == 'parley'">
                {{ data.item.gameType }}
              </span>
              <span
                v-else-if="data.item.channelType == 3"
                class="px-2"
              >
                {{ data.item.gameName }}
              </span>
              <span v-else>{{ "#" + data.item.no }}</span>
            </template>
            <template #cell(amount)="data">
              {{ data.item.amount | currency(data.item.ccy) }}
            </template>
            <template #cell(incrAmount)="data">
              {{ data.item.incrAmount | currency(data.item.ccy) }}
            </template>
            <template #cell(payout)="data">
              <span v-if="data.item.channelType == 3"> - </span>
              <span v-else>
                {{ data.item.payout }}
              </span>
            </template>
            <template #cell(liveNo)="data">
              <span v-if="data.item.channelType == 3"> - </span>
              <span v-else>
                {{ data.item.liveNo }}
              </span>
            </template>
            <template #cell(selectedBet)="data">
              <b-link
                v-if="data.item.betType == 'parley'"
                class="link-underline"
                @click="showParleyDetail(data.item)"
              >
                {{ data.item.type }}
              </b-link>
              <span
                v-else
                :class="'text-capitalize text-' + data.item.selectedBet"
              >
                <span>
                  {{ data.item.selectedBet }}
                </span>
              </span>
            </template>
            <template #cell(matchResult)="data">
              <span
                v-if="data.item.betType == 'parley'"
                class="text-capitalize"
              >
                <span
                  v-if="data.item.parleyResult == 1"
                  class="text-capitalize text-draw"
                >
                  {{ $t("general.win") }}
                </span>
                <span
                  v-if="data.item.parleyResult == 0"
                  class="text-capitalize text-meron"
                >
                  {{ $t("general.loss") }}
                </span>
              </span>
              <span
                v-if="data.item.matchResult"
                :class="'text-capitalize text-' + data.item.matchResult"
              >
                <span>
                  {{ data.item.matchResult }}
                </span>
              </span>
            </template>
            <template #cell(winAmount)="data">
              <span
                :class="{
                  'text-wala': data.item.winAmount >= 0,
                  'text-meron': data.item.winAmount < 0,
                }"
              >
                {{ data.item.winAmount | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(commission)="data">
              <span class="text-meron">
                {{ data.item.commission | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(newWl)="data">
              <span
                :class="{
                  'text-wala': data.item.newWl >= 0,
                  'text-meron': data.item.newWl < 0,
                }"
              >
                {{ data.item.newWl | currency(data.item.ccy) }}
              </span>
            </template>
            <template #cell(paybackAmount)="data">
              <span
                :class="{
                  'text-wala': data.item.paybackAmount >= 0,
                  'text-meron': data.item.paybackAmount < 0,
                }"
              >
                {{ data.item.paybackAmount | currency(data.item.ccy) }}
              </span>
            </template>
            <template #custom-foot>
              <b-tr v-if="!loading">
                <b-th colspan="5" />
                <b-th class="text-center">
                  <h5 class="font-weight-bold">
                    {{ totalReport.totalBetAmount | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th />
                <b-th />
                <b-th class="text-center">
                  <h5 class="font-weight-bold">
                    {{ total }}
                  </h5>
                </b-th>
                <b-th />
                <b-th class="text-center">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-wala': totalReport.totalWinAmount >= 0,
                      'text-meron': totalReport.totalWinAmount < 0,
                    }"
                  >
                    {{ totalReport.totalWinAmount | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-center">
                  <h5 class="font-weight-bold text-meron">
                    {{
                      totalReport.totalCommission | currency(totalReport.ccy)
                    }}
                  </h5>
                </b-th>
                <b-th class="text-center">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-wala': totalReport.totalNewWl >= 0,
                      'text-meron': totalReport.totalNewWl < 0,
                    }"
                  >
                    {{ totalReport.totalNewWl | currency(totalReport.ccy) }}
                  </h5>
                </b-th>
                <b-th class="text-center">
                  <h5
                    class="font-weight-bold"
                    :class="{
                      'text-wala': totalReport.totalPaybackAmount >= 0,
                      'text-meron': totalReport.totalPaybackAmount < 0,
                    }"
                  >
                    {{
                      totalReport.totalPaybackAmount | currency(totalReport.ccy)
                    }}
                  </h5>
                </b-th>
                <b-th />
              </b-tr>
            </template>
          </n-table>
          <n-pagination
            ref="jackpotHistoryPagination"
            class="mt-2"
            :total="total"
            :per-page="$store.state.pagination.perPage"
            :page="params.page"
            @change="list"
          />
        </div>
      </b-card>
    </b-modal>
    <parley-report-detail
      ref="parleyReportHistoryDetail"
      report-id="parleyReportHistoryDetail"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BTr,
  BTh,
  BModal,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NTable from '@/components/NTable'
import ParleyReportDetail from '@/views/parleyReport/parleyDetail/Index'
import { calculateDatesByPeriod } from '@/libs/helper.js'
import moment from 'moment'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import currency from 'currency.js'
import searchInputs from './searchInput'
import tableFields from './tableField'

const FightBetHistoryRepository = Repository.get('fightBetHistory')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
    BModal,
    BLink,
    ParleyReportDetail,
    StatisticCardHorizontal,
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      totalReport: {},
      summary: {},
      params: {
        page: 1,
        order: '',
        sort: '',
        userId: '',
        startDate: moment().format('YYYY-MM-DD'),
        toDate: moment().format('YYYY-MM-DD'),
        period: 'today',
      },
      totalReport: {},
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
  },
  watch: {
    perPage(value) {
      this.list()
    },
    'params.period': function (value) {
      const dates = calculateDatesByPeriod(value)
      if (dates.length) {
        this.params.startDate = dates[0]
        this.params.toDate = dates[1]
      }
    },
  },
  methods: {
    currencyFormat(value, currencyCode = null, precision = 2) {
      if (typeof value === 'undefined' || value == null) {
        return ''
      }

      const p = this.countDecimalDigits(value)

      if (currencyCode === 840) {
        const USD = value => currency(value, { precision: p, symbol: '$' })
        return USD(value).format()
      } if (currencyCode === 116) {
        const RIEL = value => currency(value, { precision: p, symbol: '៛' })
        return RIEL(value).format()
      } if (currencyCode === 764) {
        const C = value => currency(value, { precision: p, symbol: '฿' })
        return C(value).format()
      } if (currencyCode === 418) {
        const C = value => currency(value, { precision: p, symbol: '₭' })
        return C(value).format()
      }
      const C = value => currency(value, { precision: p, symbol: '' })
      return C(value).format()
    },
    countDecimalDigits(number) {
      const numberString = number.toString()

      const decimalIndex = numberString.indexOf('.')
      if (decimalIndex !== -1) {
        let decimalDigits = numberString.length - decimalIndex - 1
        while (decimalDigits > 0 && numberString.endsWith('0')) {
          numberString.slice(0, -1)
          decimalDigits--
        }

        return decimalDigits || 0
      }

      return 0
    },
    show(params) {
      this.$bvModal.show('modal-bet-history')
      this.isShow = true
      this.params = {
        ...params,
      }
      this.getSummary()
      this.list()
    },
    hide() {
      this.isShow = false
      this.$bvModal.hide('modal-bet-history')
    },
    list(page = 1) {
      this.params.page = page
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.getData()
    },
    search(searchText) {
      this.params.page = 1
      this.params.search = searchText
      this.$refs.jackpotHistoryPagination.reset()
      this.getData()
    },
    reset() {
      this.$refs.search.reset()
      this.params.page = 1
      this.params.startDate = moment().format('YYYY-MM-DD')
      this.params.toDate = moment().format('YYYY-MM-DD')
      this.params.period = 'today'
      this.$refs.jackpotHistoryPagination.reset()
      this.getData()
    },
    getSummary() {
      this.loading = true
      FightBetHistoryRepository.summary({
        ...this.params,
      })
        .then(response => {
          const data = response?.data?.data
          if (data) {
            this.summary = { ...data }
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getData() {
      this.loading = true
      FightBetHistoryRepository.index({
        ...this.params,
      })
        .then(response => {
          const data = response.data.data.list
          this.items = [...data]
          this.total = response.data.data.total
          this.totalReport = {}
          this.loading = false
          this.getTotal()
          this.$refs.jackpotHistoryPagination.setPaginationLabel()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getTotal() {
      FightBetHistoryRepository.total({
        ...this.params,
      }).then(response => {
        const { data } = response.data
        this.totalReport = {
          ...data,
        }
      })
    },
    showParleyDetail(data) {
      this.$refs.parleyReportHistoryDetail.show(data)
    },
  },
  setup() {
    const fields = [...tableFields]
    const searchFields = [...searchInputs]
    return {
      fields,
      searchFields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
