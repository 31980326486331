<template>
  <div class="py-2">
    <b-row>
      <b-col cols="12">
        <h4>
          {{ $t("field.lossPayout") }}
        </h4>
      </b-col>
    </b-row>
    <div v-for="(data, idx) in value" :key="`row-${idx}`" class="match-group">
      <b-row>
        <b-col md="12" class="text-right">
          <b-button
            variant="danger"
            size="sm"
            @click="removeMatchByIndex(idx)"
            :disabled="value.length <= 0"
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :vid="`loseAmount-${idx}`"
              :name="$t('field.from')"
              rules="required|decimal:2"
            >
              <label>
                {{ $t("field.lossAmount") }}
              </label>
              <n-currency-input
                v-model="data.loseAmount"
                :placeholder="$t('field.lossAmount')"
                name="loseAmount"
                :class="errors.length > 0 ? 'is-invalid' : null"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              :vid="`dropPercent-${idx}`"
              :name="$t('field.to')"
              rules="required|decimal:2"
            >
              <label>
                {{ $t("field.dropPercent") }}
              </label>
              <b-form-input
                v-model="data.dropPercent"
                :placeholder="$t('field.dropPercent')"
                name="dropPercent"
                :class="errors.length > 0 ? 'is-invalid' : null"
                autocomplete="off"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
    <b-row v-if="!readonly">
      <b-col md="6">
        <b-button
          variant="danger"
          block
          @click="removeMatch()"
          class="mt-1"
          :disabled="value.length <= 0"
        >
          {{ $t("button.remove") }}
        </b-button>
      </b-col>
      <b-col md="6">
        <b-button variant="primary" block @click="addMatch()" class="mt-1">
          {{ $t("button.add") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BButton } from "bootstrap-vue";
import NCurrencyInput from "@/components/NCurrencyInput";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    NCurrencyInput,
  },
  props: {
    value: {},
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeMatchByIndex(index) {
      if (this.value.length <= 0) {
        return;
      }

      let matches = [...this.value];
      matches.splice(index, 1);
      this.$emit("input", matches);
    },
    removeMatch() {
      if (this.value.length <= 0) {
        return;
      }

      let matches = [...this.value];
      matches.splice(matches.length - 1, 1);
      this.$emit("input", matches);
    },
    addMatch() {
      let matches = [...this.value];
      matches.push({
        channelId: null,
        startPayout: null,
        toPayout: null,
        percentMaron: null,
        percentWala: null,
      });
      this.$emit("input", matches);
    },
    getFightResultById(id) {
      if (id === 1) {
        return "meron";
      } else if (id === 2) {
        return "wala";
      } else if (id === 3) {
        return "draw";
      } else if (id === 4) {
        return "cancel";
      }

      return "";
    },
  },
  setup() {},
};
</script>