<template>
  <div>
    <live-setting
      :channel="channel"
      :channel-options="channelOptions"
      :channel-item="channelItem"
      v-if="$can('update', 'live-setup')"
    />
  </div>
</template>

<script>
import LiveSetting from "./liveSetting/LiveSetting.vue";

export default {
  components: {
    LiveSetting,
  },
  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    channelItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>