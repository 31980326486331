<template>
  <div>
    <!-- Search Container Card -->
    <n-search-container
      v-if="searchFields.length"
      :active="true"
      :show-header="false"
      :loading="loading"
      @search="search"
      @reset="reset"
    >
      <n-search-input
        ref="search"
        v-model="params"
        :fields="searchFields"
      >
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              :ref="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                v-for="option in item.field.options"
                :key="option.value"
                :value="option.value"
              >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
          sticky-header
          class="live-bet-table"
          @sort-changed="sortChanged"
        >
          <template #cell(channelId)="data">
            <span v-if="data.item.channelId === 3">
              {{ $t("field.baccarat") }}
            </span>
            <span v-else> #{{ data.item.channelId }} </span>
          </template>
          <template #cell(no)="data">
            #{{ data.item.no }}
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #cell(totalSteakKhr)="data">
            {{ data.item.totalSteak.totalSteak116 | currency }}
          </template>
          <template #cell(totalSteakUsd)="data">
            {{ data.item.totalSteak.totalSteak840 | currency }}
          </template>
          <template #cell(totalSteakThai)="data">
            {{ data.item.totalSteak.totalSteak764 | currency }}
          </template>
          <template #cell(totalSteakLao)="data">
            {{ data.item.totalSteak.totalSteak418 | currency }}
          </template>
          <template #cell(result)="data">
            <span
              v-if="data.item.result"
              :class="'text-capitalize text-' + data.item.resultId"
            >
              <span v-if="channel !== 3">
                {{ data.item.result }}
              </span>
              <span v-else>
                {{ $t(`baccarat.${data.item.resultId}`) }}
              </span>
            </span>
          </template>
          <template #cell(revenue)="data">
            <span
              :class="{
                'text-wala': data.item.revenue > 0,
                'text-meron': data.item.revenue < 0,
              }"
            >
              {{ data.item.revenue | currency }}
            </span>
          </template>
          <template #cell(outstanding)="data">
            <span class="text-meron">
              {{ data.item.totalOutstanding.meron | currency(data.item.ccy) }}
            </span>
            |
            <span class="text-wala">
              {{ data.item.totalOutstanding.wala | currency(data.item.ccy) }}
            </span>
            <br>
            <span class="text-draw">
              {{ data.item.totalOutstanding.draw | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <b-button
                v-if="$can('update', 'flight-history')"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
                @click="openBetHistory(data.item)"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                v-if="$can('update', 'flight-history')"
                v-b-tooltip.hover
                :title="$t('general.update')"
                variant="primary"
                pill
                size="sm"
                @click="openModal(data.item)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </div>
          </template>

          <template #custom-foot>
            <b-tr v-if="!loading">
              <b-th colspan="5" />
              <b-th>
                <h5 class="font-weight-bold">
                  {{ totalReport.totalSteak116 | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th>
                <h5 class="font-weight-bold">
                  {{ totalReport.totalSteak840 | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th>
                <h5 class="font-weight-bold">
                  {{ totalReport.totalSteak764 | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th>
                <h5 class="font-weight-bold">
                  {{ totalReport.totalSteak418 | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th class="text-center">
                <h5
                  v-if="totalReport.totalOutstanding"
                  class="font-weight-bold"
                >
                  <span class="text-meron">
                    {{
                      totalReport.totalOutstanding.meron
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  |
                  <span class="text-wala">
                    {{
                      totalReport.totalOutstanding.wala
                        | currency(totalReport.ccy)
                    }}
                  </span>
                  <br>
                  <span class="text-draw">
                    {{
                      totalReport.totalOutstanding.draw
                        | currency(totalReport.ccy)
                    }}
                  </span>
                </h5>
              </b-th>
              <b-th colspan="1" />
              <b-th>
                <h5
                  class="font-weight-bold"
                  :class="{
                    'text-wala': totalReport.totalRevenue > 0,
                    'text-meron': totalReport.totalRevenue < 0,
                  }"
                >
                  {{ totalReport.totalRevenue | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th colspan="1" />
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          ref="pagination"
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        />
      </div>
    </b-card>

    <bet-history-modal ref="betHistoryModal" />

    <b-modal
      :id="`updateFightResult`"
      :title="$t('general.update')"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.save')"
      :cancel-title="$t('button.close')"
      centered
      @ok="handleOk"
    >
      <validation-observer ref="updateResultForm">
        <b-form>
          <b-form-group
            :label-for="`fightResult`"
            class="fightResult"
          >
            <validation-provider
              #default="{ errors }"
              vid="fightResult"
              :name="$t('field.fightingResult')"
              rules="required"
            >
              <label>
                {{ $t("field.fightingResult") }}
              </label>
              <div>
                <b-form-radio-group
                  id="fightResult"
                  v-model="data.fightResult"
                  name="fightResult"
                  buttons
                  button-variant="outline-primary"
                >
                  <b-form-radio
                    v-for="option in resultOptions"
                    :key="option.value"
                    :class="`fightResult-${getFightResultById(option.value)}`"
                    :value="option.value"
                  >{{ $t(option.text) }}</b-form-radio>
                </b-form-radio-group>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <template #modal-ok>
        <b-spinner
          v-if="loading"
          small
          label="Spinning"
        />
        <span v-else>{{ $t("button.save") }}</span>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BLink,
  BFormRadioGroup,
  BFormRadio,
  BForm,
  BSpinner,
  BTh,
  BTr,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Repository from '@/repositories/RepositoryFactory'
import NPagination from '@/components/NPagination'
import NSearchContainer from '@/components/NSearchContainer'
import NSearchInput from '@/components/NSearchInput'
import NButtonDelete from '@/components/NButtonDelete'
import NTable from '@/components/NTable'
import { calculateDatesByPeriod } from '@/libs/helper.js'
import moment from 'moment'
import TableFields from './tableFields'
import SearchInputs from './searchInput'
import BetHistoryModal from './betHistory/List.vue'

const FightSetupRepository = Repository.get('fightSetup')

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NButtonDelete,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BLink,
    BFormRadioGroup,
    BFormRadio,
    BForm,
    BSpinner,
    BTh,
    BTr,
    BetHistoryModal,
  },
  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      data: {
        id: null,
        fightResult: null,
        channelId: null,
        bettingStatus: 3,
      },
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        startDate: this.$route.query.startDate || moment().format('YYYY-MM-DD'),
        endDate: this.$route.query.endDate || moment().format('YYYY-MM-DD'),
        period: this.$route.query.period || 'today',
      },
      totalReport: {},
    }
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage
      },
      set(value) {
        this.$store.commit('pagination/SET_PER_PAGE', value)
      },
    },
    activeSearch() {
      return !!this.$route.query.startDate || !!this.$route.query.endDate
    },
    resultOptions() {
      if (this.channelItem.type == 2) {
        return [
          { text: 'field.player', value: 2 },
          { text: 'field.tie', value: 3 },
          { text: 'field.cancel', value: 4 },
          { text: 'field.banker', value: 1 },
        ]
      }
      return [
        { text: 'field.meron', value: 1 },
        { text: 'field.draw', value: 3 },
        { text: 'field.cancel', value: 4 },
        { text: 'field.wala', value: 2 },
      ]
    },
  },
  watch: {
    perPage(value) {
      this.list(1)
    },
    'params.period': function (value) {
      const dates = calculateDatesByPeriod(value)
      if (dates.length) {
        this.params.startDate = dates[0]
        this.params.endDate = dates[1]
      }
    },
    channel() {
      this.list(1)
    },
  },
  mounted() {
    this.getData(this.params.page)
  },
  methods: {
    openBetHistory(item) {
      this.$refs.betHistoryModal.show({
        fightMatchId: item.id,
      })
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params })
    },
    list(page = 1) {
      this.params.page = page
      this.updateQuerySting()
      this.getData()
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy
      this.params.sort = sortDirection
      this.updateQuerySting()
      this.getData(this.params.page)
    },
    search() {
      this.params.page = 1
      this.$refs.pagination.reset()
      this.updateQuerySting()
      this.getData(this.params.page)
    },
    reset() {
      this.$refs.search.reset()
      this.params.page = 1
      this.$refs.pagination.reset()
      this.params.startDate = moment().format('YYYY-MM-DD')
      this.params.endDate = moment().format('YYYY-MM-DD')
      this.params.period = 'today'
      this.updateQuerySting()
      this.getData(this.page)
    },
    spliceItem(id) {
      const deleteIndex = this.items.findIndex(item => item.id === id)

      this.items.splice(deleteIndex, 1)
      this.total -= 1
    },
    getData() {
      this.loading = true
      this.items = []
      this.totalReport = {}
      FightSetupRepository.listFight({
        ...this.params,
        channelId: this.channel,
      })
        .then(response => {
          const data = response.data.data.list
          this.items = [...data]
          this.total = response.data.data.total
          this.loading = false
          this.getTotal()
        })
        .catch(() => {
          this.loading = false
        })
    },
    getTotal() {
      FightSetupRepository.listFightTotal({
        ...this.params,
        channelId: this.channel,
      })
        .then(response => {
          const { data } = response.data
          this.totalReport = {
            ...data,
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    getFightResultById(id) {
      if (id === 1) {
        return 'meron'
      } if (id === 2) {
        return 'wala'
      } if (id === 3) {
        return 'draw'
      } if (id === 4) {
        return 'cancel'
      }

      return ''
    },
    openModal(data) {
      this.data.id = data.id
      this.data.fightResult = data.resultId
      this.data.channelId = data.channelId
      this.$bvModal.show('updateFightResult')
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.updateResultForm.validate().then(success => {
        if (success) {
          this.update()
        }
      })
    },
    update() {
      if (this.loading) {
        return
      }
      this.loading = true
      const data = {
        fightResult: this.data.fightResult,
        fightMatchId: this.data.id,
      }
      FightSetupRepository.reUpdateFightResult(data)
        .then(response => {
          this.$bvModal.hide('updateFightResult')
          this.getData()
        })
        .catch(error => {
          if (error.response?.status == 422) {
            this.$refs.updateResultForm.setErrors(error.response?.data?.errors)
          }
        })
        .then(() => {
          this.loading = false
        })
    },
  },
  setup() {
    const fields = TableFields
    const searchFields = SearchInputs
    return {
      fields,
      searchFields,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
