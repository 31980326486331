var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[(_vm.$can('read', 'fight-data'))?_c('b-col',{attrs:{"md":"6"}},[_c('b-card',{attrs:{"header-tag":"header"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h2',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.channelText)+" | "),(_vm.data.channelId > 2)?_c('span',[_vm._v(" Live "+_vm._s(_vm.data.liveNo)+" | "+_vm._s(_vm.$t("general.matchWithNumber", { fight: _vm.data.no }))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.fightWithNumber", { fight: _vm.data.no }))+" ")])])]},proxy:true}],null,false,2508105769)},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"streamSetupForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('n-form-confirmation',{key:"streamSetupForm",attrs:{"form":_vm.$refs.streamSetupForm,"disabled":invalid}},[_c('n-input',{ref:"fightInput",attrs:{"fields":_vm.fields,"init-value":_vm.initData},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)]}}],null,false,1681915905)})],1)],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":_vm.$can('read', 'fight-data') ? 6 : 6}},[_c('b-card',{scopedSlots:_vm._u([(!_vm.$can('read', 'fight-data'))?{key:"header",fn:function(){return [_c('h2',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.channelText)+" | "+_vm._s(_vm.$t("general.fightWithNumber", { fight: _vm.data.no }))+" ")])]},proxy:true}:null],null,true)},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[(_vm.live.videoLink && _vm.live.videoType === 'link')?_c('b-embed',{attrs:{"type":"iframe","aspect":"16by9","src":_vm.live.videoLink,"allowfullscreen":""}}):_vm._e(),(_vm.live.videoLink && _vm.live.videoType === 'iframe')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.live.videoLink)}}):_vm._e()],1)],1),(_vm.$can('update', 'live-stream'))?_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"mt-2"},[_c('div',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("field.bettingStatus")))])]),_c('b-button-group',{staticClass:"betting-status-group"},_vm._l((_vm.bettingStatus),function(bs,idx){return _c('b-button',{key:("bs-" + idx),staticClass:"btn-betting mr-2",class:("bettingStatus-" + (bs.value)),attrs:{"disabled":_vm.isDisableStatus(bs.value)},on:{"click":function($event){return _vm.confirm('status', bs.value, bs.text)}}},[_vm._v(" "+_vm._s(_vm.$t(bs.text))+" ")])}),1)],1),_c('div',[_c('div',[_c('label',{attrs:{"for":""}},[_vm._v(_vm._s(_vm.$t("field.fightingResult")))])]),_c('b-button-group',{staticClass:"fight-result-group"},_vm._l((_vm.fightResult),function(fr,idx){return _c('b-button',{key:("fr-" + idx),staticClass:"btn-betting mr-2",class:("fightResult-" + (fr.value)),attrs:{"loading":_vm.loading,"disabled":_vm.loading ||
                      _vm.data.bettingStatus !== 3 ||
                      _vm.data.fightResult > 0},on:{"click":function($event){return _vm.confirm('result', fr.value, fr.text)}}},[_vm._v(" "+_vm._s(_vm.$t(fr.text))+" ")])}),1)],1),_c('b-row',{staticClass:"mt-2"},[(_vm.$can('update', 'fight-data'))?_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(_vm.$can('update', 'live-stream'))?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"cg_btn",attrs:{"type":"button","variant":"primary","block":"","disabled":_vm.loading || _vm.data.bettingStatus != 2},on:{"click":_vm.showBetSettingModal}},[_vm._v(" "+_vm._s(_vm.$t("button.updateData"))+" ")]):_vm._e()],1):_vm._e(),_c('b-col',{staticClass:"text-right",attrs:{"cols":"6"}},[(
                    _vm.$can('update', 'live-stream') &&
                      _vm.isAbleToNexMatch &&
                      _vm.data.id
                  )?_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"cg_btn",attrs:{"type":"button","variant":"success","block":"","loading":_vm.loading,"disabled":!_vm.data.id},on:{"submit":function($event){return _vm.confirm('next')}}},[_vm._v(" "+_vm._s(_vm.$t("button.nextMatch"))+" ")]):_vm._e()],1),_c('b-col',{staticClass:"mt-2",attrs:{"cols":"12"}},[(
                    _vm.$can('update', 'live-stream') &&
                      _vm.isAbleToNexMatch &&
                      _vm.data.id
                  )?_c('n-button-loading',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"cg_btn",attrs:{"type":"button","variant":"info","block":"","loading":_vm.loading,"disabled":!_vm.data.id},on:{"submit":_vm.showSkipMatchModal}},[_vm._v(" "+_vm._s(_vm.$t("button.skipMatch"))+" ")]):_vm._e()],1)],1)],1)],1):_vm._e()],1)],1)],1),_c('fight-result',{ref:"fightResult",attrs:{"channel":_vm.channel,"channel-item":_vm.channelItem}}),_c('betting-result',{attrs:{"results":_vm.results}}),_c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('button.ok'),"cancel-title":_vm.$t('button.close'),"centered":"","title":_vm.$t('general.liveStreamUpdate'),"size":"xl"},on:{"ok":_vm.update},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('validation-observer',{ref:"popupForm"},[_c('n-input',{ref:"inputUpdate",staticClass:"data-form",attrs:{"fields":_vm.fieldsUpdate},model:{value:(_vm.updatePayload),callback:function ($$v) {_vm.updatePayload=$$v},expression:"updatePayload"}})],1)],1),_c('b-modal',{attrs:{"cancel-variant":"outline-secondary","ok-title":_vm.$t('button.ok'),"cancel-title":_vm.$t('button.close'),"centered":"","size":"sm"},on:{"ok":_vm.skipMatch},model:{value:(_vm.isShowSkipMatchModal),callback:function ($$v) {_vm.isShowSkipMatchModal=$$v},expression:"isShowSkipMatchModal"}},[_c('validation-observer',{ref:"skipMatchForm"},[_c('n-input',{ref:"inputUpdate",attrs:{"fields":_vm.skipMatchFormInput},model:{value:(_vm.skipMatchData),callback:function ($$v) {_vm.skipMatchData=$$v},expression:"skipMatchData"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }