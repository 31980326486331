<template>
  <div>
    <live-stream
      :channel="channel"
      :channel-options="channelOptions"
      :channel-item="channelItem"
      v-if="$can('read', 'live-stream') || $can('update', 'live-stream')"
    />
  </div>
</template>

<script>
import LiveStream from "./liveStream/LiveStream.vue";

export default {
  components: {
    LiveStream,
  },
  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    channelItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>
