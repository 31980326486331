<template>
  <div>
    <b-form-group>
      <b-form-radio-group
        id="btn-radios-1"
        v-model="channel"
        :options="channelOptions"
        name="radios-btn-default"
        buttons
        button-variant="light"
        class="channel-switch"
        @input="updateQuerySting"
      ></b-form-radio-group>
    </b-form-group>

    <b-tabs pills v-model="tabIndex">
      <b-tab lazy v-if="$can('read', 'live-betting')">
        <template #title>
          <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("breadcrumb.liveSetup") }}</span>
        </template>

        <live-setup
          :channel="channel"
          :channel-options="channelOptions"
          :channel-item="channelItem"
        />
      </b-tab>

      <b-tab
        lazy
        v-if="$can('read', 'live-stream') || $can('update', 'live-stream')"
      >
        <template #title>
          <feather-icon icon="TvIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("breadcrumb.liveSteam") }}</span>
        </template>

        <live-stream
          :channel="channel"
          :channel-options="channelOptions"
          :channel-item="channelItem"
        />
      </b-tab>

      <b-tab lazy v-if="$can('read', 'fight-data-form')">
        <template #title>
          <feather-icon icon="DatabaseIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">{{ $t("breadcrumb.dataForm") }}</span>
        </template>

        <data-form
          :channel="channel"
          :channel-options="channelOptions"
          :channel-item="channelItem"
        />
      </b-tab>
    </b-tabs>
    <load-profile></load-profile>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import tabRoute from "./tabRoute";
import LiveSetup from "./LiveSetup.vue";
import LiveStream from "./LiveStream.vue";
import DataForm from "./DataForm.vue";

export default {
  components: {
    BTabs,
    BTab,
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
    LiveStream,
    LiveSetup,
    DataForm,
  },
  data() {
    return {
      channel: null,
    };
  },
  computed: {
    tabIndex: {
      get() {
        let routeName = this.$route.name;
        if (routeName === "live-betting") {
          if (
            this.$can("read", "live-setup") ||
            this.$can("read", "flight-history")
          ) {
            routeName = "live-setup";
          } else if (this.$can("read", "live-stream")) {
            routeName = "live-stream";
          } else if (this.$can("read", "fight-data-form")) {
            routeName = "data-form";
          }
        }

        let index = this.route.findIndex((item) => {
          return item.route === routeName;
        });

        return index !== -1 ? index : 0;
      },
      set(value) {
        const route = this.route[value]?.route;
        if (route) {
          this.$router.replace({
            name: route,
          });
        }
      },
    },
    channelOptions() {
      let userData = this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
      let channelOptions = userData.channels.map((row) => {
        return {
          text: row.channelName,
          value: row.id,
          type: row.channelType,
        };
      });

      if (channelOptions.length) {
        let activeChannel = 0;
        this.channel = channelOptions[activeChannel].value;
      }

      return channelOptions;
    },
    channelItem: function () {
      const index = this.channelOptions.findIndex((row) => {
        return row.value == this.channel;
      });
      if (index != -1) {
        return this.channelOptions[index];
      }
      return {};
    },
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({
        query: {
          channel: this.channel,
        },
      });
    },
  },
  setup() {
    const route = [...tabRoute];

    return { route };
  },
};
</script>
