<template>
  <div>
    <data-form
      :channel="channel"
      :channel-options="channelOptions"
      :channel-item="channelItem"
      v-if="$can('read', 'fight-data-form')"
    />
  </div>
</template>

<script>
import DataForm from "./dataForm/DataForm.vue";

export default {
  components: {
    DataForm,
  },
  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    channelItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>