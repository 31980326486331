<template>
  <div>
    <b-card>
      <b-row class="mb-2">
        <b-col>
          <h2 class="mb-0">{{ channelText }}</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <h3>M99</h3>
        </b-col>
        <b-col md="6">
          <b-embed
            v-if="data.videoLink && data.videoType === 'link'"
            type="iframe"
            aspect="16by9"
            :src="data.videoLink"
            allowfullscreen
          ></b-embed>
          <div
            v-if="data.videoLink && data.videoType === 'iframe'"
            v-html="data.videoLink"
          ></div>
        </b-col>
        <b-col md="6" class="cg_align_self_center">
          <validation-observer ref="liveSetupForm" #default="{ invalid }">
            <!-- form -->
            <n-form-confirmation
              key="liveSetupForm"
              @submit="submit"
              :form="$refs.liveSetupForm"
              :disabled="invalid"
            >
              <n-input
                ref="input"
                :fields="fields"
                v-model="data"
                :initValue="initData"
              ></n-input>
              <b-row>
                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="submit"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="cg_btn mt-1"
                    block
                    :loading="loading"
                    :disabled="invalid"
                    v-if="$can('update', 'live-setup')"
                  >
                    {{ $t("button.saveChanges") }}
                  </n-button-loading>
                </b-col>
                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="data.liveStatus ? `danger` : `success`"
                    class="cg_btn mt-1"
                    block
                    :loading="loading"
                    :disabled="!data.id"
                    v-if="$can('update', 'live-setup')"
                    @submit="changeLiveStatus"
                  >
                    {{
                      data.liveStatus
                        ? $t("button.stopLive")
                        : $t("button.goLive")
                    }}
                  </n-button-loading>
                </b-col>

                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="`warning`"
                    class="cg_btn mt-1"
                    block
                    @submit="pending_modal = true"
                    :loading="loading"
                    v-if="$can('update', 'live-setup')"
                  >
                    {{ $t("button.message") }}
                  </n-button-loading>
                </b-col>

                <b-col cols="12" class="text-right">
                  <n-button-loading
                    type="button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-danger"
                    class="cg_btn mt-1"
                    block
                    :loading="loading"
                    :disabled="invalid"
                    v-if="$can('update', 'live-setup')"
                    @submit="resetLive"
                  >
                    {{ $t("button.resetLive") }}
                  </n-button-loading>
                </b-col>
              </b-row>
            </n-form-confirmation>
          </validation-observer>
        </b-col>
      </b-row>
      <b-row
        v-for="sub in data.sub"
        :key="`sub-live-${sub.domain}`"
        class="mt-2"
      >
        <b-col cols="12">
          <hr />
          <h3>{{ sub.domain }}</h3>
        </b-col>
        <b-col md="6">
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="sub.videoLink"
            allowfullscreen
          ></b-embed>
        </b-col>
        <b-col md="6" class="cg_align_self_center">
          <validation-observer
            :ref="`liveSetupForm-${sub.domain}`"
            #default="{ invalid }"
          >
            <!-- form -->
            <n-form-confirmation
              :key="`liveSetupForm-${sub.domain}`"
              @submit="submitSubLive(sub)"
              :form="$refs[`liveSetupForm-${sub.domain}`]"
              :disabled="invalid"
            >
              <validation-provider
                #default="{ errors }"
                :vid="`videoLink-${sub.domain}`"
                :name="$t('field.videoEmbedLink')"
                rules="required"
              >
                <b-form-group :label="$t('field.videoEmbedLink')">
                  <b-form-input
                    :name="`videoLink-${sub.domain}`"
                    v-model="sub.videoLink"
                  ></b-form-input>

                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <b-row>
                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="submit"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="cg_btn mt-1"
                    block
                    :loading="loading"
                    :disabled="invalid"
                    v-if="$can('update', 'live-setup')"
                  >
                    {{ $t("button.saveChanges") }}
                  </n-button-loading>
                </b-col>

                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="sub.liveStatus ? `danger` : `success`"
                    class="cg_btn mt-1"
                    block
                    :loading="loading"
                    v-if="$can('update', 'live-setup')"
                    @submit="changeSubLiveStatus(sub)"
                  >
                    {{
                      sub.liveStatus
                        ? $t("button.stopLive")
                        : $t("button.goLive")
                    }}
                  </n-button-loading>
                </b-col>

                <b-col cols="4" class="text-right">
                  <n-button-loading
                    type="button"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    :variant="`warning`"
                    class="cg_btn mt-1"
                    block
                    @submit="showSubLiveMessage(sub)"
                    :loading="loading"
                    v-if="$can('update', 'live-setup')"
                  >
                    {{ $t("button.message") }}
                  </n-button-loading>
                </b-col>
              </b-row>
            </n-form-confirmation>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
    <list-match
      :channel="channel"
      :channel-item="channelItem"
      v-if="$can('read', 'flight-history')"
    ></list-match>
    <b-modal
      ok-variant="primary"
      cancel-variant="outline-info"
      modal-class="modal-dark"
      centered
      @ok="submitPending"
      v-model="pending_modal"
      :title="$t('alert.inputYourMessage')"
    >
      <validation-observer ref="stopMessageForm">
        <validation-provider
          #default="{ errors }"
          :vid="'stop_message_live'"
          :name="$t('general.message')"
        >
          <b-form-group>
            <b-form-input
              :class="errors.length > 0 ? 'is-invalid' : null"
              v-model="data.stopMessage"
              :placeholder="$t('general.message')"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </b-modal>
    <b-modal
      id="modal-message"
      ok-variant="primary"
      cancel-variant="outline-info"
      modal-class="modal-dark"
      centered
      @ok="submitSubLive(subLive)"
      :title="$t('alert.inputYourMessage')"
    >
      <validation-observer ref="stopSubMessageForm">
        <validation-provider
          #default="{ errors }"
          :vid="'stop_message_live'"
          :name="$t('general.message')"
        >
          <b-form-group>
            <b-form-input
              :class="errors.length > 0 ? 'is-invalid' : null"
              v-model="subLive.stopMessage"
              :placeholder="$t('general.message')"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BEmbed,
  BFormGroup,
  BFormInput,
  BModal,
  BRow,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import NInput from "@/components/NInput";
import NFormConfirmation from "@/components/NFormConfirmation";
import NButtonLoading from "@/components/NButtonLoading";
import formInput from "./formInput";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import ListMatch from "./ListMatch.vue";

const FightSetupRepository = Repository.get("fightSetup");
const ccy = 116;

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BEmbed,
    BFormRadio,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    ListMatch,
  },
  directives: {
    Ripple,
  },
  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    channelItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    channelText: function () {
      return this.channelItem.text;
    },
  },
  data() {
    return {
      data: {
        videoLink: null,
        perBet: 0,
        totalBet: 0,
        liveStatus: false,
        videoType: "link",
        stopMessage: null,
      },
      initData: {},
      loading: false,
      pending_modal: false,
      subLive: {},
    };
  },
  created() {
    this.show();
  },
  mounted() {
    this.checkVideoType();
  },
  watch: {
    "data.videoLink": function (value) {
      this.checkVideoType();
    },
    channel: function () {
      this.channelChanged();
    },
  },
  methods: {
    channelChanged() {
      this.data = {
        videoLink: null,
        perBet: 0,
        totalBet: 0,
        liveStatus: false,
        videoType: "link",
        stopMessage: null,
        sub: [],
      };
      this.show();
    },
    updateSetting(value, settingIndex) {
      this.data.setting[settingIndex] = {
        ...value,
      };
    },
    checkVideoType() {
      if (this.data.videoLink) {
        if (this.data.videoLink.search("iframe") >= 0)
          this.data.videoType = "iframe";
        else this.data.videoType = "link";
      } else {
        this.videoType = "link";
      }
    },
    show() {
      const data = {
        channel_id: this.channel,
      };
      FightSetupRepository.current(data).then((response) => {
        const data = response.data.data;
        if (data) {
          const index = data.setting.findIndex((item) => {
            return item.ccy === ccy;
          });

          this.data = {
            ...data,
            perBet: index !== -1 ? data.setting[index].perBet : 0,
            totalBet: index !== -1 ? data.setting[index].totalBet : 0,
          };
          this.checkVideoType();
        }

        this.setInputValue(this.data);
      });
    },
    setInputValue(data) {
      if (this.$refs.input) this.$refs.input.setValue(data);
      else {
        setTimeout((x) => {
          this.setInputValue(data);
        }, 500);
      }
    },
    resetLive() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.resetLive"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.resetLive"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const data = {
              channel_id: this.channel,
            };
            FightSetupRepository.resetLive(data)
              .then((response) => {})
              .catch((error) => {
                if (error.response?.status == 422) {
                  this.$refs.liveSetupForm.setErrors(
                    error.response?.data?.errors
                  );
                }
              })
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
    submit() {
      this.$refs.liveSetupForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          const data = {
            ...this.data,
            channel_id: this.channel,
            setting: [
              {
                ccy: ccy,
                perBet: this.data.perBet,
                totalBet: this.data.totalBet,
              },
            ],
          };
          FightSetupRepository.setup(data)
            .then((response) => {
              this.data = {
                ...data,
                channel_id: this.channel,
                id: response.data?.data?.id,
              };
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.liveSetupForm.setErrors(
                  error.response?.data?.errors
                );
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
    showSubLiveMessage(sub) {
      this.subLive = {
        ...sub,
      };
      this.$bvModal.show("modal-message");
    },
    changeSubLiveStatus(sub) {
      this.$bvModal
        .msgBoxConfirm(
          sub.liveStatus
            ? this.$t("alert.youAreGoingToStopLive")
            : this.$t("alert.youAreGoingToLive"),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: sub.liveStatus ? "danger" : "success",
            okTitle: sub.liveStatus
              ? this.$t("button.stopLive")
              : this.$t("button.goLive"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.loading = true;
            const data = {
              ...sub,
              fightLiveId: this.data.id,
              liveStatus: !sub.liveStatus,
            };
            FightSetupRepository.setupSubLive(data)
              .then((response) => {
                const res = response?.data?.data;
                if (res) {
                  this.data = {
                    ...res,
                  };
                  this.checkVideoType();
                }
              })
              .catch((error) => {
                if (error.response?.status == 422) {
                  this.$refs.liveSetupForm.setErrors(
                    error.response?.data?.errors
                  );
                }
              })
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
    submitSubLive(sub) {
      this.loading = true;
      const data = {
        ...sub,
        fightLiveId: this.data.id,
      };
      FightSetupRepository.setupSubLive(data)
        .then((response) => {
          const res = response?.data?.data;
          if (res) {
            this.data = {
              ...res,
            };
            this.checkVideoType();
          }
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.liveSetupForm.setErrors(error.response?.data?.errors);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    changeLiveStatus() {
      if (!this.data.id) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(
          this.data.liveStatus
            ? this.$t("alert.youAreGoingToStopLive")
            : this.$t("alert.youAreGoingToLive"),
          {
            title: this.$t("alert.areYouSure"),
            size: "sm",
            buttonSize: "sm",
            okVariant: this.data.liveStatus ? "danger" : "success",
            okTitle: this.data.liveStatus
              ? this.$t("button.stopLive")
              : this.$t("button.goLive"),
            cancelTitle: this.$t("button.cancel"),
            cancelVariant: "outline-secondary",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.loading = true;
            const data = {
              id: this.data.id,
              channel_id: this.channel,
              status: this.data.liveStatus ? 0 : 1,
            };

            FightSetupRepository.changeLiveStatus(data)
              .then((response) => {
                this.data.liveStatus = !this.data.liveStatus;
              })
              .catch()
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
    stopLive() {
      if (!this.data.id) {
        return;
      }

      this.$bvModal
        .msgBoxConfirm(this.$t("alert.youAreGoingToStopLive"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.stopLive"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            const data = {
              id: this.data.id,
              channel_id: this.channel,
              status: false,
            };

            FightSetupRepository.changeLiveStatus(data)
              .then((response) => {
                this.data.liveStatus = false;
                this.data.stopMessage = null;
              })
              .catch()
              .then(() => {
                this.loading = false;
              });
          }
        });
    },
    submitPending(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.stopMessageForm.validate().then((x) => {
        if (x) {
          this.pending_modal = false;
          this.loading = true;
          const data = {
            id: this.data.id,
            channel_id: this.channel,
            status: this.data.liveStatus,
            stopMessage: this.data.stopMessage,
          };

          FightSetupRepository.changeLiveStatus(data)
            .then((response) => {
              this.data.stopMessage = this.data.stopMessage;
            })
            .catch()
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const fields = [...formInput];

    return { fields };
  },
};
</script>
