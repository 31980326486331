<template>
  <div>
    <b-card header-tag="header">
      <b-row class="mb-2">
        <b-col>
          <h2 class="mb-0">{{ channelText }}</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <validation-observer ref="streamSetupForm" #default="{ invalid }">
            <!-- form -->
            <n-form-confirmation
              key="streamSetupForm"
              @submit="submit"
              :form="$refs.streamSetupForm"
              :disabled="invalid"
            >
              <n-input
                ref="input"
                :fields="fields"
                v-model="data"
                :initValue="initData"
                class="data-form"
              >
                <template #fakeRangePayout>
                  <fake-payout-form v-model="data.fakeRangePayout" />
                </template>
                <template #loseRangePayout>
                  <lose-payout-form v-model="data.loseRangePayout" />
                </template>
              </n-input>
              <b-row>
                <b-col cols="12" class="text-right">
                  <n-button-loading
                    type="submit"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mt-1"
                    block
                    :loading="loading"
                    :disabled="invalid"
                    v-if="$can('update', 'fight-data-form')"
                  >
                    {{ $t("button.saveChanges") }}
                  </n-button-loading>
                </b-col>
              </b-row>
            </n-form-confirmation>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BEmbed,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BRow,
} from "bootstrap-vue";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import Ripple from "vue-ripple-directive";
import Repository from "@/repositories/RepositoryFactory";
import formInput from "./formInput";
import baccaratFormInput from "./baccaratFormInput";
import NButtonLoading from "@/components/NButtonLoading";
import FakePayoutForm from "./FakePayoutForm.vue";
import LosePayoutForm from "./LosePayoutForm.vue";

const FightSetupRepository = Repository.get("fightSetup");
const ccy = 116;

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BEmbed,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    NInput,
    NFormConfirmation,
    NButtonLoading,
    FakePayoutForm,
    LosePayoutForm,
  },
  directives: {
    Ripple,
  },
  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    channelItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    channelText: function () {
      return this.channelItem.text;
    },
    fields() {
      if (this.channelItem.type == 2) {
        return baccaratFormInput;
      }

      return formInput;
    },
  },
  data() {
    return {
      data: {
        automaticNext: null,
        fightDuration: null,
        higherMeronPayout: null,
        higherWalaPayout: null,
        lowerMeronPayout: null,
        lowerWalaPayout: null,
        meronPayoutRatio: null,
        radioAmountMeron: null,
        radioAmountWala: null,
        walaPayoutRatio: null,
        baccaratRange: null,
        fakeRangePayout: [],
        loseRangePayout: [],
      },
      initData: {},
      loading: false,
    };
  },
  watch: {
    "data.fightDurationMin": function (value) {
      this.toSecond();
    },
    "data.suspendDurationMin": function (value) {
      this.toSecond();
    },
    "data.automaticNextMin": function (value) {
      this.toSecond();
    },
    channel: function () {
      this.channelChanged();
    },
  },
  created() {
    this.show();
  },
  methods: {
    channelChanged() {
      this.data = {
        automaticNext: null,
        fightDuration: null,
        higherMeronPayout: null,
        higherWalaPayout: null,
        lowerMeronPayout: null,
        lowerWalaPayout: null,
        meronPayoutRatio: null,
        radioAmountMeron: null,
        radioAmountWala: null,
        walaPayoutRatio: null,
        fakeRangePayout: [],
      };
      this.initData = {};
      this.show();
    },
    show() {
      const data = {
        channel_id: this.channel,
      };
      FightSetupRepository.getDefaultFight(data).then((response) => {
        const data = response.data.data;
        if (data) {
          this.data = {
            ...data,
          };
          this.toMinute();
          this.$refs.input.setValue(this.data);
        }
      });
    },
    toSecond() {
      if (
        parseFloat(this.data.suspendDurationMin) ||
        this.data.suspendDurationMin == 0
      ) {
        this.data.suspendDuration = this.data.suspendDurationMin * 60;
      }
    },
    toMinute() {
      if (parseFloat(this.data.suspendDuration)) {
        this.data.suspendDurationMin = this.data.suspendDuration / 60;
      } else {
        this.data.suspendDurationMin = 0;
      }
    },
    submit() {
      this.$refs.streamSetupForm.validate().then((success) => {
        if (success) {
          this.loading = true;
          this.data.automaticNext = 0;
          const data = {
            ...this.data,
            fakeRangePayout: this.data.fakeRangePayout.map((element) => {
              return {
                ...element,
                channelId: this.data.channelId,
              };
            }),
          };
          FightSetupRepository.updateDefaultFight(data)
            .then((response) => {
              const data = response.data.data;
              this.data = {
                ...this.data,
                ...data,
              };
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.streamSetupForm.setErrors(
                  error.response?.data?.errors
                );
              }
            })
            .then(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>
