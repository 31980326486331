<template>
  <div>
    <b-row>
      <b-col
        v-if="$can('read', 'fight-data')"
        md="6"
      >
        <b-card header-tag="header">
          <template #header>
            <h2 class="mb-0">
              {{ channelText }} |
              <span v-if="data.channelId > 2">
                Live {{ data.liveNo }} |
                {{ $t("general.matchWithNumber", { fight: data.no }) }}
              </span>
              <span v-else>
                {{ $t("general.fightWithNumber", { fight: data.no }) }}
              </span>
            </h2>
          </template>
          <b-row>
            <b-col md="12">
              <validation-observer
                ref="streamSetupForm"
                #default="{ invalid }"
              >
                <!-- form -->
                <n-form-confirmation
                  key="streamSetupForm"
                  :form="$refs.streamSetupForm"
                  :disabled="invalid"
                >
                  <n-input
                    ref="fightInput"
                    v-model="data"
                    :fields="fields"
                    :init-value="initData"
                  />
                </n-form-confirmation>
              </validation-observer>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col :md="$can('read', 'fight-data') ? 6 : 6">
        <b-card>
          <template
            v-if="!$can('read', 'fight-data')"
            #header
          >
            <h2 class="mb-0">
              {{ channelText }} |
              {{ $t("general.fightWithNumber", { fight: data.no }) }}
            </h2>
          </template>
          <b-row>
            <b-col md="12">
              <b-embed
                v-if="live.videoLink && live.videoType === 'link'"
                type="iframe"
                aspect="16by9"
                :src="live.videoLink"
                allowfullscreen
              />
              <div
                v-if="live.videoLink && live.videoType === 'iframe'"
                v-html="live.videoLink"
              />
            </b-col>
          </b-row>
          <b-row v-if="$can('update', 'live-stream')">
            <b-col md="12">
              <div class="mt-2">
                <div>
                  <label for="">{{ $t("field.bettingStatus") }}</label>
                </div>
                <b-button-group class="betting-status-group">
                  <b-button
                    v-for="(bs, idx) in bettingStatus"
                    :key="`bs-${idx}`"
                    class="btn-betting mr-2"
                    :class="`bettingStatus-${bs.value}`"
                    :disabled="isDisableStatus(bs.value)"
                    @click="confirm('status', bs.value, bs.text)"
                  >
                    {{ $t(bs.text) }}
                  </b-button>
                </b-button-group>
              </div>

              <div>
                <div>
                  <label for="">{{ $t("field.fightingResult") }}</label>
                </div>
                <b-button-group class="fight-result-group">
                  <b-button
                    v-for="(fr, idx) in fightResult"
                    :key="`fr-${idx}`"
                    class="btn-betting mr-2"
                    :class="`fightResult-${fr.value}`"
                    :loading="loading"
                    :disabled="
                      loading ||
                        data.bettingStatus !== 3 ||
                        data.fightResult > 0
                    "
                    @click="confirm('result', fr.value, fr.text)"
                  >
                    {{ $t(fr.text) }}
                  </b-button>
                </b-button-group>
              </div>

              <b-row class="mt-2">
                <b-col
                  v-if="$can('update', 'fight-data')"
                  cols="6"
                  class="text-right"
                >
                  <b-button
                    v-if="$can('update', 'live-stream')"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="primary"
                    class="cg_btn"
                    block
                    :disabled="loading || data.bettingStatus != 2"
                    @click="showBetSettingModal"
                  >
                    {{ $t("button.updateData") }}
                  </b-button>
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  <n-button-loading
                    v-if="
                      $can('update', 'live-stream') &&
                        isAbleToNexMatch &&
                        data.id
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="success"
                    class="cg_btn"
                    block
                    :loading="loading"
                    :disabled="!data.id"
                    @submit="confirm('next')"
                  >
                    {{ $t("button.nextMatch") }}
                  </n-button-loading>
                </b-col>
                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <n-button-loading
                    v-if="
                      $can('update', 'live-stream') &&
                        isAbleToNexMatch &&
                        data.id
                    "
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="button"
                    variant="info"
                    class="cg_btn"
                    block
                    :loading="loading"
                    :disabled="!data.id"
                    @submit="showSkipMatchModal"
                  >
                    {{ $t("button.skipMatch") }}
                  </n-button-loading>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <fight-result
      ref="fightResult"
      :channel="channel"
      :channel-item="channelItem"
    />
    <betting-result :results="results" />
    <b-modal
      v-model="showModal"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.close')"
      centered
      :title="$t('general.liveStreamUpdate')"
      size="xl"
      @ok="update"
    >
      <validation-observer ref="popupForm">
        <n-input
          ref="inputUpdate"
          v-model="updatePayload"
          :fields="fieldsUpdate"
          class="data-form"
        />
      </validation-observer>
    </b-modal>
    <b-modal
      v-model="isShowSkipMatchModal"
      cancel-variant="outline-secondary"
      :ok-title="$t('button.ok')"
      :cancel-title="$t('button.close')"
      centered
      size="sm"
      @ok="skipMatch"
    >
      <validation-observer ref="skipMatchForm">
        <n-input
          ref="inputUpdate"
          v-model="skipMatchData"
          :fields="skipMatchFormInput"
        />
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BEmbed,
  BFormGroup,
  BFormRadio,
  BFormRadioGroup,
  BModal,
  BRow,
  BButtonGroup,
  BButton,
} from 'bootstrap-vue'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInput from '@/components/NInput'
import Ripple from 'vue-ripple-directive'
import Repository from '@/repositories/RepositoryFactory'
import NButtonLoading from '@/components/NButtonLoading'
import db from '@/libs/firestore-db'
import { camelizeKeys } from 'humps'
import Swal from 'sweetalert2'
import formInputRead from './formInputRead'
import baccaratFormInputRead from './baccaratFormInputRead'
import baccaratFormInputUpdate from './baccaratFormInputUpdate'
import skipMatchFormInput from './skipMatchFormInput'
import formInputUpdate from './formInputUpdate'
import FightResult from './FightResult'
import BettingResult from './BettingResult'

const FightSetupRepository = Repository.get('fightSetup')
const ccy = 116

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BEmbed,
    NInput,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    NFormConfirmation,
    NButtonLoading,
    FightResult,
    BettingResult,
    BModal,
    BButtonGroup,
    BButton,
  },

  directives: {
    Ripple,
  },

  props: {
    channel: {
      type: Number,
      default: 1,
    },
    channelOptions: {
      type: Array,
      default: () => [],
    },
    channelItem: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      loading: false,
      isAbleToNexMatch: false,
      isShowSkipMatchModal: false,
      skipMatchFormInput,
      skipMatchData: {
        toNo: null,
      },
      listenFbChannel: null,
      live: {
        videoLink: null,
        liveStatus: false,
        videoType: 'link',
      },

      listenFbMatch: null,
      data: {
        no: null,
        meronPayoutRatio: null,
        walaPayoutRatio: null,
        totalMeronBet: 0,
        totalWalaBet: 0,
        meronBetTotalKh: 0,
        walaBetTotalKh: 0,
        meronBetTotalUsd: 0,
        walaBetTotalUsd: 0,
        radioAmountMeron: null,
        radioAmountWala: null,
        flightDuration: null,
        automateNext: null,
        bettingStatus: null,
        fightResult: null,
        drawPayoutRatio: 0,
      },
      initData: {},

      bettingStatus: [
        { text: 'field.open', value: 2 },
        { text: 'field.close', value: 3 },
        { text: 'field.suspend', value: 4 },
      ],

      showModal: false,
      updatePayload: {},

      items: {},
      results: [],

      listenFbResult: null,
      resultNo: null,
    }
  },

  computed: {
    channelText() {
      return this.channelItem.text
    },
    fields() {
      if (this.channelItem.type == 2) {
        return baccaratFormInputRead
      }

      return formInputRead
    },
    fieldsUpdate() {
      if (this.channelItem.type == 2) {
        return baccaratFormInputUpdate
      }

      return formInputUpdate
    },
    fightResult() {
      if (this.channelItem.type == 2) {
        return [
          { text: 'field.player', value: 2 },
          { text: 'field.tie', value: 3 },
          { text: 'field.cancel', value: 4 },
          { text: 'field.banker', value: 1 },
        ]
      }

      return [
        { text: 'field.meron', value: 1 },
        { text: 'field.draw', value: 3 },
        { text: 'field.cancel', value: 4 },
        { text: 'field.wala', value: 2 },
      ]
    },
  },

  watch: {
    channel() {
      this.channelChanged()
      this.listenScoreBoard()
    },
    resultNo(value) {
      if (value) {
        // this.listMatchResult();
      }
    },
  },

  created() {
    this.listenChannel()
    this.listenMatch()
    this.listenScoreBoard()
    // this.listenResult();
  },

  methods: {
    isDisableStatus(status) {
      if (this.loading) {
        return true
      }

      if (this.data.bettingStatus == status) {
        return true
      }

      if (this.data.bettingStatus == 3 && status == 2) {
        return true
      }

      return false
    },
    listenChannel() {
      this.live.videoLink = ''
      this.listenFbChannel && this.listenFbChannel()
      this.listenFbChannel = db
        .collection('fight_live')
        .doc(`live_${this.channel}`)
        .onSnapshot(response => {
          let data = response.get('data')
          if (data) {
            data = JSON.parse(data)
            if (data.live_status) {
              this.live.videoLink = data.video_link
            }
          }
        })
    },

    listenMatch() {
      this.data = {
        channelId: this.channel,
      }
      this.setData()
      this.checkCanNextMatch()

      this.listenFbMatch && this.listenFbMatch()
      this.listenFbMatch = db
        .collection('fight_match')
        .doc(`match_${this.channel}`)
        .onSnapshot(response => {
          let data = response.get('data')
          if (data) {
            data = camelizeKeys(JSON.parse(data))
            const { setting } = data
            const index = setting.findIndex(row => row.ccy == ccy)
            let radioAmountMeron = 0
            let radioAmountWala = 0
            let secondRadioAmountMeron = 0
            let secondRadioAmountWala = 0
            if (index !== -1) {
              radioAmountMeron = setting[index].radioAmountMeron
              radioAmountWala = setting[index].radioAmountWala
              secondRadioAmountMeron = setting[index].secondRadioAmountMeron
              secondRadioAmountWala = setting[index].secondRadioAmountWala
            }

            console.log(data)
            this.data = {
              ...data,
              drawBetTotal116: data.bets.drawBetTotal116,
              drawBetTotal418: data.bets.drawBetTotal418,
              drawBetTotal764: data.bets.drawBetTotal764,
              drawBetTotal840: data.bets.drawBetTotal840,
              meronBetTotal116: data.bets.meronBetTotal116,
              meronBetTotal418: data.bets.meronBetTotal418,
              meronBetTotal764: data.bets.meronBetTotal764,
              meronBetTotal840: data.bets.meronBetTotal840,
              walaBetTotal116: data.bets.walaBetTotal116,
              walaBetTotal418: data.bets.walaBetTotal418,
              walaBetTotal764: data.bets.walaBetTotal764,
              walaBetTotal840: data.bets.walaBetTotal840,
              channelId: this.channel,
              suspendDurationMin: this.toMinute(data.suspendDuration),
              radioAmountMeron,
              radioAmountWala,
              secondRadioAmountMeron,
              secondRadioAmountWala,
            }

            this.setData()
            this.checkCanNextMatch()
          }
        })
    },

    listenResult() {
      this.listenFbResult && this.listenFbResult()
      this.listenFbResult = db
        .collection('release')
        .doc(`release_${this.channel}`)
        .onSnapshot(response => {
          this.resultNo = response.get('result_release_no')
        })
    },

    listenScoreBoard() {
      this.listenFbScoreBoard && this.listenFbScoreBoard()
      this.listenFbScoreBoard = db
        .collection('match_result')
        .doc(`list_${this.channel}`)
        .onSnapshot(response => {
          let data = response.get('list')
          if (data) {
            data = JSON.parse(data)

            this.results = [...data]
          } else {
            this.results = []
          }
          this.$refs.fightResult.mapResult(this.results)
        })
    },

    setData() {
      if (typeof this.data !== 'undefined') {
        if (this.$refs.fightInput) {
          this.$refs.fightInput.setValue(this.data)
        } else {
          setTimeout(() => {
            this.setData()
          }, 500)
        }
      }
    },

    channelChanged() {
      this.live.videoLink = null
      this.listenChannel()
      this.listenMatch()
      // this.listenResult();
    },

    listMatchResult() {
      const data = {
        channelId: this.channel,
      }
      FightSetupRepository.listFightResult(data).then(response => {
        this.results = response.data.data
        this.$refs.fightResult.mapResult(this.results)
      })
    },

    toMinute(second) {
      if (parseFloat(second)) {
        return second / 60
      }
      return (second = 0)
    },

    toSecond(minute) {
      if (parseFloat(minute) || minute == 0) {
        return minute * 60
      }
    },

    checkCanNextMatch() {
      if (this.data.bettingStatus === 3 || this.data.bettingStatus === 4) {
        this.isAbleToNexMatch = true
      } else {
        this.isAbleToNexMatch = false
      }
    },

    confirm(type, data = null, text = null) {
      let confirmButton = ''
      let confirmButtonClass = ''
      if (type == 'result') {
        confirmButtonClass = `btn btn-lg ml-1 fightResult-${data}`
        confirmButton = this.$t(text)
      } else if (type == 'next') {
        confirmButtonClass = 'btn btn-lg btn-success ml-1'
        confirmButton = this.$t('button.nextMatch')
      } else if (type == 'status') {
        confirmButtonClass = `btn btn-lg ml-1 bettingStatus-${data}`
        confirmButton = this.$t(text)
      }

      Swal.fire({
        text: this.$t('alert.areYouSure'),
        icon: 'info',
        customClass: {
          cancelButton: 'btn btn-lg btn-outline-secondary',
          confirmButton: confirmButtonClass,
        },
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: confirmButton,
        cancelButtonText: this.$t('button.cancel'),
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          if (type == 'status') {
            this.updateBettingStatus(data)
          } else if (type == 'result') {
            this.updateFightResult(data)
          } else if (type == 'next') {
            this.nextMatch()
          }
        }
      })
    },

    updateBettingStatus(status) {
      this.loading = true
      FightSetupRepository.updateBettingStatus({
        bettingStatus: status,
        channelId: this.data.channelId,
      })
        .then(response => {
          if (response?.data?.data) {
            this.data = {
              ...response.data.data,
              suspendDurationMin: this.toMinute(
                response.data.data.suspendDuration,
              ),
            }
            this.setRatioAmount(response.data.data.setting)
            this.checkCanNextMatch()
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },

    updateFightResult(result) {
      this.loading = true
      FightSetupRepository.updateFightResult({
        fightResult: result,
        channelId: this.data.channelId,
      })
        .then(response => {
          if (response?.data?.data) {
            this.data = {
              ...response.data.data,
              suspendDurationMin: this.toMinute(
                response.data.data.suspendDuration,
              ),
            }
            this.setRatioAmount(response.data.data.setting)
            this.checkCanNextMatch()
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },

    nextMatch() {
      this.loading = true
      FightSetupRepository.nextMatch({
        channelId: this.data.channelId,
      })
        .then(response => {
          if (response?.data?.data) {
            this.data = {
              ...response.data.data,
              suspendDurationMin: this.toMinute(
                response.data.data.suspendDuration,
              ),
            }
            this.setRatioAmount(response.data.data.setting)
            this.checkCanNextMatch()
          }
          this.loading = false
        })
        .catch(error => {
          this.loading = false
        })
    },

    setRatioAmount(setting) {
      const index = setting.findIndex(row => row.ccy == ccy)
      let radioAmountMeron = 0
      let radioAmountWala = 0
      let secondRadioAmountMeron = 0
      let secondRadioAmountWala = 0
      if (index != -1) {
        radioAmountMeron = setting[index].radioAmountMeron
        radioAmountWala = setting[index].radioAmountWala
        secondRadioAmountMeron = setting[index].secondRadioAmountMeron
        secondRadioAmountWala = setting[index].secondRadioAmountWala
      }
      this.data.radioAmountMeron = radioAmountMeron
      this.data.radioAmountWala = radioAmountWala
      this.data.secondRadioAmountMeron = secondRadioAmountMeron
      this.data.secondRadioAmountWala = secondRadioAmountWala
    },

    showBetSettingModal() {
      this.showModal = true
      this.updatePayload = { ...this.data }
      setTimeout(() => {
        if (this.showModal) {
          this.$refs.inputUpdate.setValue(this.updatePayload)
        }
      }, 500)
    },

    showSkipMatchModal() {
      this.isShowSkipMatchModal = true
    },
    skipMatch(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.$refs.skipMatchForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.isShowSkipMatchModal = false

          FightSetupRepository.skipMatch({
            ...this.skipMatchData,
            channelId: this.data.channelId,
          })
            .then(response => {
              this.loading = false
            })
            .catch(error => {
              if (error.response?.status == 422) {
                this.$refs.skipMatchForm.setErrors(
                  error.response?.data?.errors,
                )
              }
              this.loading = false
            })
        }
      })
    },

    update(bvModalEvt) {
      bvModalEvt.preventDefault()

      this.$refs.popupForm.validate().then(success => {
        if (success) {
          this.loading = true
          this.showModal = false
          const data = {
            ...this.updatePayload,
            suspendDuration: this.toSecond(
              this.updatePayload.suspendDurationMin,
            ),
            setting: [
              {
                ccy,
                radioAmountMeron: this.updatePayload.radioAmountMeron,
                radioAmountWala: this.updatePayload.radioAmountWala,
                secondRadioAmountMeron: this.updatePayload.secondRadioAmountMeron,
                secondRadioAmountWala: this.updatePayload.secondRadioAmountWala,
              },
            ],
          }

          FightSetupRepository.updateFight(data)
            .then(response => {
              if (response?.data?.data) {
                this.data = {
                  ...response.data.data,
                  suspendDurationMin: this.toMinute(
                    response.data.data.suspendDuration,
                  ),
                }
                this.setRatioAmount(response.data.data.setting)
                this.checkCanNextMatch()
              }
              this.loading = false
            })
            .catch(error => {
              if (error.response?.status == 422) {
                this.$refs.popupForm.setErrors(error.response?.data?.errors)
              }
              this.loading = false
            })
        }
      })
    },
  },
  setup() {},
}
</script>
