export default [
  {
    route: 'live-setup',
  },
  {
    route: 'live-stream',
  },
  {
    route: 'data-form',
  }
];