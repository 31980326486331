export default [
  {
    key: 'walaPayoutRatio',
    label: 'field.playerPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'wala',
    cols: 4,
    disabled: true
  },
  {
    key: 'lowerWalaPayout',
    label: 'field.lowerPlayerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'wala',
    cols: 4,
    disabled: true
  },
  {
    key: 'higherWalaPayout',
    label: 'field.higherPlayerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'wala',
    cols: 4,
    disabled: true
  },
  {
    key: 'walaBetTotal116',
    label: 'field.rielPlayerBet',
    rules: '',
    type: 'currency',
    class: 'wala',
    disabled: true,
    cols: 4,
  },
  {
    key: 'walaBetTotal840',
    label: 'field.dollarPlayerBet',
    rules: '',
    type: 'currency',
    class: 'wala',
    disabled: true,
    cols: 4,
  },
  {
    key: 'walaBetTotal764',
    label: 'field.thaiPlayerBet',
    rules: '',
    type: 'currency',
    class: 'wala',
    disabled: true,
    cols: 4,
  },
  {
    key: 'walaBetTotal418',
    label: 'field.laoPlayerBet',
    rules: '',
    type: 'currency',
    class: 'wala',
    disabled: true,
    cols: 4,
  },

  {
    key: 'walaBetTotal',
    label: 'field.totalPlayerBet',
    rules: '',
    type: 'currency',
    class: 'wala',
    disabled: true,
    cols: 8,
  },

  {
    key: 'radioAmountWala',
    label: 'field.playerRatioAmount',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'wala',
    cols: 6,
    disabled: true,

  },
  {
    key: 'fightDuration',
    label: 'field.statusDuration',
    rules: 'required|integer|min_value:0',
    type: 'text',
    cols: 6,
    disabled: true,
  },
  {
    key: 'meronPayoutRatio',
    label: 'field.bankerPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'meron',
    cols: 4,
    disabled: true
  },
  {
    key: 'lowerMeronPayout',
    label: 'field.lowerBankerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'meron',
    cols: 4,
    disabled: true
  },
  {
    key: 'higherMeronPayout',
    label: 'field.higherBankerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'meron',
    cols: 4,
    disabled: true
  },
  {
    key: 'meronBetTotal116',
    label: 'field.rielBankerBet',
    rules: '',
    type: 'currency',
    class: 'meron',
    disabled: true,
    cols: 4,
  },
  {
    key: 'meronBetTotal840',
    label: 'field.dollarBankerBet',
    rules: '',
    type: 'currency',
    class: 'meron',
    disabled: true,
    cols: 4,
  },
  {
    key: 'meronBetTotal764',
    label: 'field.thaiBankerBet',
    rules: '',
    type: 'currency',
    class: 'meron',
    disabled: true,
    cols: 4,
  },
  {
    key: 'meronBetTotal418',
    label: 'field.laoBankerBet',
    rules: '',
    type: 'currency',
    class: 'meron',
    disabled: true,
    cols: 4,
  },
  {
    key: 'meronBetTotal',
    label: 'field.totalBankerBet',
    rules: '',
    type: 'currency',
    class: 'meron',
    disabled: true,
    cols: 8,
  },
  {
    key: 'radioAmountMeron',
    label: 'field.bankerRatioAmount',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'meron',
    cols: 6,
    disabled: true,
  },
  {
    key: 'suspendDurationMin',
    label: 'field.suspendDurationMin',
    rules: 'required|integer|min_value:0',
    type: 'text',
    cols: 6,
    disabled: true,
  },
  {
    key: 'drawPayoutRatio',
    label: 'field.tiePayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'draw',
    cols: 4,
    disabled: true,
  },
  {
    key: 'drawBetTotal116',
    label: 'field.reilTieBet',
    rules: '',
    type: 'text',
    class: 'draw',
    cols: 4,
    disabled: true,
  },
  {
    key: 'drawBetTotal840',
    label: 'field.dollarTieBet',
    rules: '',
    type: 'text',
    class: 'draw',
    cols: 4,
    disabled: true,
  },
  {
    key: 'drawBetTotal764',
    label: 'field.thaiTieBet',
    rules: '',
    type: 'text',
    class: 'draw',
    cols: 4,
    disabled: true,
  },
  {
    key: 'drawBetTotal418',
    label: 'field.laoTieBet',
    rules: '',
    type: 'text',
    class: 'draw',
    cols: 4,
    disabled: true,
  },
  {
    key: 'drawBetTotal',
    label: 'field.totalTieBet',
    rules: '',
    type: 'text',
    class: 'draw',
    cols: 4,
    disabled: true,
  },
]
