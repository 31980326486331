export default [
  {
    key: 'meronPayoutRatio',
    label: 'field.meronPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'lowerMeronPayout',
    label: 'field.lowerMeronPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'walaPayoutRatio',
    label: 'field.walaPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'wala',
    cols: 3,
  },

  {
    key: 'lowerWalaPayout',
    label: 'field.lowerWalaPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'wala',
    cols: 3,
  },
  {
    key: 'higherMeronPayout',
    label: 'field.higherMeronPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'radioAmountMeron',
    label: 'field.meronRatioAmount',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'higherWalaPayout',
    label: 'field.higherWalaPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'wala',
    cols: 3,
  },
  {
    key: 'radioAmountWala',
    label: 'field.walaRatioAmount',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'wala',
    cols: 3,
  },
  // {
  //   key: 'secondRadioAmountMeron',
  //   label: 'field.meronSecondRatioAmount',
  //   rules: 'required|decimal:2|min_value:0',
  //   type: 'currency',
  //   class: 'meron',
  //   cols: 3,
  // },
  // {
  //   key: 'secondLowerMeronPayout',
  //   label: 'field.secondLowerMeronPayout',
  //   rules: 'required|decimal:2|min_value:0',
  //   type: 'currency',
  //   class: 'meron',
  //   cols: 3,
  // },
  // {
  //   key: 'secondRadioAmountWala',
  //   label: 'field.walaSecondRatioAmount',
  //   rules: 'required|decimal:2|min_value:0',
  //   type: 'currency',
  //   class: 'wala',
  //   cols: 3,
  // },
  // {
  //   key: 'secondLowerWalaPayout',
  //   label: 'field.secondLowerWalaPayout',
  //   rules: 'required|decimal:2|min_value:0',
  //   type: 'currency',
  //   class: 'wala',
  //   cols: 3,
  // },
  {
    key: 'drawPayoutRatio',
    label: 'field.drawPayoutRatio',
    rules: 'required|min_value:0',
    type: 'currency',
    class: 'draw',
    cols: 12,
  },
  {
    key: 'fightDuration',
    label: 'field.statusDuration',
    rules: 'required|decimal|min_value:0',
    type: 'text',
    cols: 6,
  },
  {
    key: 'suspendDurationMin',
    label: 'field.suspendDurationMin',
    rules: 'required|integer|min_value:0',
    type: 'text',
    cols: 6,
  },
  {
    cols: 12,
  },
  {
    key: 'isPayoutRandom',
    label: 'field.randomPayout',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    key: 'isDropPayoutLose',
    label: 'field.dropPayout',
    rules: '',
    type: 'checkbox',
    cols: 'auto',
  },
  {
    cols: 12,
  },
  {
    key: 'fakeRangePayout',
    label: 'field.fakePayout',
    cols: 12,
  },
  {
    key: 'loseRangePayout',
    label: 'field.lossPayout',
    cols: 12,
  },
]
