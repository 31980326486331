export default [
  {
    key: 'walaPayoutRatio',
    label: 'field.playerPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'wala',
    cols: 3,
  },
  {
    key: 'lowerWalaPayout',
    label: 'field.lowerPlayerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'wala',
    cols: 3,
  },
  {
    key: 'meronPayoutRatio',
    label: 'field.bankerPayoutRatio',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'lowerMeronPayout',
    label: 'field.lowerBankerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'higherWalaPayout',
    label: 'field.higherPlayerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'wala',
    cols: 3,
  },

  {
    key: 'radioAmountWala',
    label: 'field.playerRatioAmount',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'wala',
    cols: 3,
  },
  {
    key: 'higherMeronPayout',
    label: 'field.higherBankerPayout',
    rules: 'required|decimal:2|min_value:0',
    type: 'text',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'radioAmountMeron',
    label: 'field.bankerRatioAmount',
    rules: 'required|decimal:2|min_value:0',
    type: 'currency',
    class: 'meron',
    cols: 3,
  },
  {
    key: 'drawPayoutRatio',
    label: 'field.tiePayoutRatio',
    rules: 'required|min_value:0',
    type: 'currency',
    class: 'draw',
    cols: 12,
  },
  {
    key: 'fightDuration',
    label: 'field.statusDuration',
    rules: 'required|decimal|min_value:0',
    type: 'text',
    cols: 6,
  },
  {
    key: 'closeDuration',
    label: 'field.closeDuration',
    rules: 'required|decimal|min_value:0',
    type: 'text',
    cols: 6,
  },
  {
    key: 'suspendDurationMin',
    label: 'field.suspendDurationMin',
    rules: 'required|integer|min_value:0',
    type: 'text',
    cols: 12,
  },
  {
    key: 'baccaratRange',
    label: 'field.baccaratRange',
    rules: 'required|integer|min_value:0',
    type: 'currency',
    cols: 6,
  },
  {
    key: 'betAmountTieChangeResult',
    label: 'field.betAmountTieChangeResult',
    rules: 'required|integer|min_value:0',
    type: 'currency',
    cols: 6,
  },
]
