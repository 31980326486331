export default [
  {
    key: 'increasement',
    sortable: false,
    sortField: '',
    label: '#',
    thClass: 'w-50px',
  },
  {
    key: 'createdAt',
    sortable: false,
    sortField: '',
    label: 'field.createDate',
  },
  {
    key: 'channelId',
    sortable: false,
    sortField: '',
    label: 'field.channel',
  },
  {
    key: 'liveNo',
    sortable: false,
    sortField: '',
    label: 'Live',
    noTranslateLabel: true,
  },
  {
    key: 'no',
    sortable: false,
    sortField: '',
    label: 'field.fight',
  },
  {
    key: 'totalSteakKhr',
    sortable: false,
    sortField: '',
    label: 'field.totalSteakKhr',
  },
  {
    key: 'totalSteakUsd',
    sortable: false,
    sortField: '',
    label: 'field.totalSteakUsd',
  },
  {
    key: 'totalSteakThai',
    sortable: false,
    sortField: '',
    label: 'field.totalSteakThai',
  },
  {
    key: 'totalSteakLao',
    sortable: false,
    sortField: '',
    label: 'field.totalSteakLao',
  },
  {
    key: 'outstanding',
    sortable: false,
    sortField: '',
    label: 'field.outstanding',
    tdClass: 'text-center',
  },
  {
    key: 'result',
    sortable: false,
    sortField: '',
    label: 'field.result',
  },
  {
    key: 'revenue',
    sortable: false,
    sortField: '',
    label: 'field.revenue',
  },
  {
    key: 'actions',
    label: 'general.action',
  },
];
